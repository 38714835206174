import router from "@/router";
import store from "./store";
import { $rrj } from "@/utils/rrjutils";
import { accountWxLogin, accountDyLogin, getUserinfo } from "@/api/login";
import { getBindMobile } from "@/api/api";
import { app, wx_app, planet_host } from "@/utils/config";
const whiteList = ["/login", "/register", "/forget", "/website", "/about"]; // 不重定向白名单

router.beforeEach(async (to, from, next) => {
  console.log(to, "start----");
  console.log(from, "from----");
  // if (from.path === to.path) {
  //   return;
  // }

  window.onscroll = null;

  // 设置登录模式
  const normalLogin = $rrj.storage.get("normalLogin");
  console.log("normalLogin = ", normalLogin);
  store.commit("setNormalLogin", { normalLogin: normalLogin });

  // 直接在路径上复制accessToken的登录方式
  const { access_token: accessToken, planetId } = to.query;
  if (accessToken) {
    cleanLoginInfo();
    const res = await getUserinfo(accessToken);
    console.log("res = ", res);
    store.commit("setToken", { token: res.token });
    store.commit("setUser", { userInfo: res.user });
    store.commit("setLoginWay", {
      loginWay: "accessToken",
    });
    $rrj.storage.set("token", res.token);
    $rrj.storage.set("user", res.user);
    $rrj.storage.set("loginWay", "accessToken");
    if (planetId) {
      return next({
        path: "/index",
        query: { planetId: planetId },
        replace: true,
      });
    }
    return next({ path: "/index", replace: true });
  }

  // 登录页已经登录则自动进入首页
  if (to.path === "/login" && !to.query.needBindPhone && !needLogin()) {
    if (normalLogin) {
      return next({ path: "/index" });
    } else {
      // return next({ path: "/adm/dashboard" });
      return next({ path: "/index" });
    }
  }

  // 走正常流程
  const { code, state } = getPageParams();
  console.log("code = ", code, "status = ", state);
  if (needLogin()) {
    if (whiteList.indexOf(to.path) !== -1) {
      if (state === "10003") next(); // 微信走正常
      else if (state === "10002")
        await login(code, state, to, next); // 抖音走登录
      else next(); // 其他走正常
    } else {
      await login(code, state, to, next);
      // next({ path: "/login", replace: true });
    }
  } else {
    console.log("store", store);
    if (!store.state.loginWay) {
      store.commit("setLoginWay", { loginWay: $rrj.storage.get("loginWay") });
    }
    if (!store.state.getUser) {
      store.commit("setUser", {
        user: $rrj.storage.get("user"),
      });
    }
    if (!store.state.getToken) {
      store.commit("setToken", {
        token: $rrj.storage.get("token"),
      });
    }
    next();
  }
});

// 检查是否需要登录
function needLogin() {
  let token = $rrj.storage.get("token");
  let user = $rrj.storage.get("user");
  if (!token || !user) {
    return true;
  }
  return new Date().getTime() > token.exp * 1000;
}

// 登录封装
async function login(code, state, to, next) {
  console.log("login start to = ", to);
  cleanLoginInfo();
  let res;
  if (state === "10002") {
    res = await accountDyLogin({ code: code, app: app });
  } else if (state === "10003") {
    res = await accountWxLogin({ code: code, app: wx_app });
  }
  if (res && res.code === 200) {
    $rrj.storage.set("token", res.data.token);
    const phone = await getBindMobileFn();
    if (phone) {
      store.commit("setToken", { token: res.data.token });
      store.commit("setUser", { userInfo: res.data.user });
      store.commit("setLoginWay", {
        loginWay: state === "10002" ? "dy" : "wx",
      });
      $rrj.storage.set("user", res.data.user);
      $rrj.storage.set("loginWay", state === "10002" ? "dy" : "wx");
      const normalLogin = $rrj.storage.get("normalLogin");
      if (normalLogin) {
        router.replace({ path: "/index" });
      } else {
        // router.replace({ path: "/adm/dashboard" });
        router.replace({ path: "/index" });
      }
    } else {
      router.replace({
        path: "/login",
        query: {
          needBindPhone: true,
          normalLogin: $rrj.storage.get("normalLogin"),
        },
      });
    }
  } else {
    cleanLoginInfo();
    if (res && res.message) {
      window.location.href =
        planet_host + "/#/login?failMessage=" + res.message;
      next({ path: `/login?failMessage=${res.message}`, replace: true });
    } else {
      window.location.href = planet_host + "/#/login";
      next({ path: "/login", replace: true });
    }
  }
}

// 清除本地的登录信息
function cleanLoginInfo() {
  store.commit("setToken", { token: null });
  store.commit("setUser", { userInfo: null });
  store.commit("setLoginWay", {
    loginWay: null,
  });
  $rrj.storage.set("token", null);
  $rrj.storage.set("user", null);
  $rrj.storage.set("loginWay", null);
}

// 解析query
function getPageParams() {
  let url = window.location.href;
  let param = {};
  url.replace(/([^?&]+)=([^?&/]+)/g, function (s, v, k) {
    param[v] = decodeURIComponent(k); //解析字符为中文
    return k + "=" + v;
  });
  return param;
}

// 获取绑定的手机号
async function getBindMobileFn() {
  const res = await getBindMobile();
  if (res && res.code === 200) {
    return res.data;
  }
  return null;
}
